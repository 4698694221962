// common.module.ts or where LangSelector is declared

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms'; // Import FormsModule
import { MatSlideToggleModule } from '@angular/material/slide-toggle'; // Import MatSlideToggleModule if using Angular Material components
import { LangSelectorBackofficeComponent } from './lang-selector-backoffice/lang-selector-backoffice.component';
import { LangSelectorCommonComponent } from './lang-selector-common/lang-selector-common.component';
import { CurrencyFormatPipe } from './currency-format-pipe';

@NgModule({
  declarations: [LangSelectorBackofficeComponent, LangSelectorCommonComponent, CurrencyFormatPipe],
  imports: [CommonModule, FormsModule, MatSlideToggleModule],
  exports: [LangSelectorBackofficeComponent, LangSelectorCommonComponent, CurrencyFormatPipe]
})
export class CommonOuterModule {}
