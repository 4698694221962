import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '@app/core/client.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  generalSettingsForm: FormGroup;
  selectOptions: any[] = [
    { value: 'du', label: 'Du' },
    { value: 'sie', label: 'Sie' }
  ];
  loading: boolean = false;

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(private fb: FormBuilder, private clientService: ClientService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.initForm();
    this.fetchSettings();
  }

  initForm() {
    this.generalSettingsForm = this.fb.group({
      pronominalForm: ['NA', Validators.required],
      metaPixelCode: [''],
      googlePixelCode: ['']
    });
  }

  fetchSettings() {
    this.loading = true;
    this.clientService
      .getClientSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        this.loading = false;
        if (response.status) {
          this.generalSettingsForm.get('pronominalForm').setValue(response.client.pronominalForm);
          this.generalSettingsForm
            .get('metaPixelCode')
            .setValue(
              response.client && response.client.clientSettings ? response.client.clientSettings.metaPixelCode : ''
            );
          this.generalSettingsForm
            .get('googlePixelCode')
            .setValue(
              response.client && response.client.clientSettings ? response.client.clientSettings.googlePixelCode : ''
            );
        } else {
          this.snackBar.open('Einstellungen konnten nicht geladen werden', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      });
  }

  saveSettings() {
    if (this.generalSettingsForm.invalid) {
      this.snackBar.open('Bitte überprüfen Sie Ihre Angaben', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
      return;
    }

    const formData = this.generalSettingsForm.value;
    this.loading = true;

    this.clientService
      .saveClientSettings(formData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe((response: any) => {
        this.loading = false;
        if (response.status) {
          this.snackBar.open('Einstellungen erfolgreich aktualisiert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        } else {
          this.snackBar.open('Die Einstellungen konnten nicht aktualisiert werden', '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      });
  }
}
