import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { map, switchMap, tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { CredentialsService } from './authentication/credentials.service';
import { AuthenticationService } from './authentication/authentication.service';
import moment, { Moment } from 'moment';

export interface LoginContext {
  email: string;
  password: string;
  remember?: boolean;
}

@Injectable()
export class ClientService {
  clients: any;
  client: any;
  clientSource = new BehaviorSubject('');
  activeClient = this.clientSource.asObservable();
  activeClientId: number;
  staffCodes: any;

  constructor(
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private router: Router,
    private authService: AuthenticationService
  ) {}

  /**
   * Retrieves the list of user clients
   * @return The user clients.
   */
  getClients(refreshData: boolean = false): Observable<any> {
    if (this.clients && !refreshData) {
      return of(this.clients);
    } else {
      const cred = this.credentialsService.credentials;
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + cred.token
      });
      return this.http
        .get<any>(environment.serverUrl + `private/clients/` + cred.id, {
          headers: headers
        })
        .pipe(
          map((clientList: any) => {
            this.clients = clientList;
            return clientList;
          })
        );
    }
  }

  validateEmail(email: string = ''): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/validate/` + email, {
        headers: headers
      })
      .pipe(
        map((msg: any) => {
          return msg;
        })
      );
  }

  setActiveClientId(clientId: number) {
    this.activeClientId = clientId;
  }

  getActiveClient() {
    return this.getClient(this.activeClientId);
  }

  changeActiveClient(client: any) {
    this.clientSource.next(client);
  }

  /**
   * Retrieves a specidfic client for public usage
   * @return The user clients.
   */
  getClient(clientId: number, refreshData: boolean = false): Observable<any> {
    if (this.client && this.client.id === clientId && !refreshData) {
      return of(this.client);
    } else {
      return this.http.get<any>(environment.serverUrl + `public/client/` + clientId).pipe(
        map((client: any) => {
          this.client = client;
          return client;
        })
      );
    }
  }

  uploadLogo(uploadData: any): Observable<any> {
    uploadData.append('clientId', this.activeClientId);
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(environment.serverUrl + `private/client/logo/`, uploadData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  getStaffCodes(refreshData: boolean = false): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (this.staffCodes && this.staffCodes.betriebId === this.authService.activeClientId && !refreshData) {
      return of(this.staffCodes);
    } else {
      return this.http
        .get<any>(environment.serverUrl + `client/staff-codes/` + this.authService.activeClientId, {
          headers: headers
        })
        .pipe(
          map((staffCodes: any) => {
            this.staffCodes = staffCodes;
            return staffCodes;
          })
        );
    }
  }

  addStaffMember(staffMemberData: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    staffMemberData.clientId = this.authService.activeClientId;
    return this.http
      .post<any>(environment.serverUrl + `client/staff-codes/`, staffMemberData, {
        headers: headers
      })
      .pipe(
        map((resp: any) => {
          this.staffCodes = [...this.staffCodes, resp];
          return resp;
        })
      );
  }

  editStaffMember(staffMemberData: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `client/staff-codes/`, staffMemberData, {
        headers: headers
      })
      .pipe(
        map((member: any) => {
          return member;
        })
      );
  }

  deleteStaffMember(staffMemberId: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `client/staff-codes/${staffMemberId}`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          // Delete from array
          this.staffCodes = this.staffCodes.filter((obj: any) => {
            return obj.id !== staffMemberId;
          });
          return res;
        })
      );
  }

  getOpeningHourOfClient(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/opening-hours/all/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((hours: any) => {
          return hours;
        })
      );
  }
  getImprintOfClient(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/imprint/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
  saveImprintOfClient(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `client/imprint/` + this.authService.activeClientId,
        { data },
        { headers: headers }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getOpeningHourOfDay(date: string, refreshData: boolean = false): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    if (this.staffCodes && this.staffCodes.betriebId === this.authService.activeClientId && !refreshData) {
      return of(this.staffCodes);
    } else {
      return this.http
        .get<any>(environment.serverUrl + `client/opening-hours/day/` + this.authService.activeClientId + '/' + date, {
          headers: headers
        })
        .pipe(
          map((client: any) => {
            return client;
          })
        );
    }
  }

  signupGastroPay(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `client/signup-gastropay/`,
        { clientId: this.authService.activeClientId },
        {
          headers: headers
        }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  checkIn(clientId: number, guestData: any): Observable<any> {
    const data = {
      clientId: clientId,
      table: guestData.table,
      reservationId: guestData.reservationId,
      guests: guestData.guests
    };
    return this.http.post<any>(environment.serverUrl + `public/checkin/`, data).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  getCheckIns(date: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/check-ins/` + this.authService.activeClientId + '/' + date, {
        headers: headers
      })
      .pipe(
        map((checkIns: any) => {
          return checkIns;
        })
      );
  }

  saveOpeningHours(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `client/opening-hours/save/` + this.authService.activeClientId,
        { data },
        { headers: headers }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  saveSpecialOpeningHours(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `client/special-opening-hours/save/` + this.authService.activeClientId,
        { data },
        { headers: headers }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getModerators(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/moderators/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((mods: any) => {
          return mods;
        })
      );
  }

  getRoles(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `private/moderators/roles`, {
        headers: headers
      })
      .pipe(
        map((roles: any) => {
          return roles;
        })
      );
  }

  getPermissions(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `private/moderators/permissions`, {
        headers: headers
      })
      .pipe(
        map((permissions: any) => {
          return permissions;
        })
      );
  }

  getPermissionsOfUser(userId: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `private/moderators/permissions/${this.authService.activeClientId}/${userId}`, {
        headers: headers
      })
      .pipe(
        map((permissions: any) => {
          return permissions;
        })
      );
  }

  saveModerator(userData: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    userData.clientId = this.authService.activeClientId;

    return this.http
      .post<any>(environment.serverUrl + `private/moderators`, userData, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  deleteModerator(userId: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .delete<any>(environment.serverUrl + `private/moderators/${this.authService.activeClientId}/${userId}`, {
        headers: headers
      })
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  checkInviteHash(hash: string) {
    return this.http.get<any>(environment.serverUrl + `public/check-invite-hash/${hash}`).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  acceptModInvite(inviteData: any) {
    return this.http.post<any>(environment.serverUrl + `public/invite`, inviteData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getFeedbacksOverview(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/feedbacks/` + this.authService.activeClientId, {
        headers: headers
      })
      .pipe(
        map((checkIns: any) => {
          return checkIns;
        })
      );
  }

  getFeedbacks(
    from: any = null,
    to: any = null,
    showReviewData: any = null,
    googleAccessToken: any = null,
    accountId: any = null,
    locationId: any = null
  ): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `client/feedbacks/` + this.authService.activeClientId,
        {
          startDate: from,
          endDate: to,
          showReviewData,
          googleAccessToken,
          accountId,
          locationId
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((checkIns: any) => {
          return checkIns;
        })
      );
  }

  saveFeedback(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `client/feedback/save/` + this.authService.activeClientId,
        { data },
        { headers: headers }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  deleteFeedback(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `client/feedback/delete/` + this.authService.activeClientId,
        { data },
        { headers: headers }
      )
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }

  getDashboard() {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .get<any>(environment.serverUrl + `client/dashboard/${this.authService.activeClientId}/${cred.id}`)
      .pipe(
        map((response: any) => {
          return response;
        })
      );
  }

  saveDashboardItem(itemData: any) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    itemData.clientId = this.authService.activeClientId;
    itemData.userId = cred.id;

    return this.http.post<any>(environment.serverUrl + `client/dashboard`, itemData).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getLogs(from: any = null, to: any = null) {
    if (from === null) {
      from = moment()
        .subtract(7, 'days')
        .format();
    }
    if (to === null) {
      to = moment().format();
    }
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });

    return this.http
      .post<any>(
        environment.serverUrl + `client/change-logs/` + this.authService.activeClientId,
        {
          startDate: from,
          endDate: to
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((stats: any) => {
          return stats;
        })
      );
  }
  saveClientRequestCodeSettings(data: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `client/update-request-staff-code/`, data, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getClientSettings(): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `client/${this.authService.activeClientId}/get-client-settings`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  saveClientSettings(formData: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .put<any>(environment.serverUrl + `client/${this.authService.activeClientId}/set-client-settings`, formData, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getNewsLetterSubscribers(currentPage?: number, pageSize?: number): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(
        `${environment.serverUrl}client/news-letter-subscribes/${this.authService.activeClientId}?page=${currentPage}&pageSize=${pageSize}`,
        {
          headers: headers
        }
      )
      .pipe(
        map((newsLetter: any) => {
          return newsLetter;
        })
      );
  }

  removeNewsLetterSubscribers(email: string) {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .delete<any>(environment.serverUrl + `client/${this.authService.activeClientId}/remove-news-letter/${email}`, {
        headers: headers
      })
      .pipe(
        map((event: any) => {
          return event;
        })
      );
  }

  AIIntegration(fromDate: any, toDate: any, question: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `client/feedback/ai-integration/` + this.authService.activeClientId,
        {
          fromDate,
          toDate,
          question
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }

  getPremiumBookings(code: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .get<any>(environment.serverUrl + `public/client/premium-bookings/${this.authService.activeClientId}/${code}`, {
        headers: headers
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  fetchAllBusinessLocations(token: any, clientId: any): Observable<any> {
    const cred = this.credentialsService.credentials;
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + cred.token
    });
    return this.http
      .post<any>(
        environment.serverUrl + `client/fetch-business-locations/`,
        {
          token,
          clientId
        },
        {
          headers: headers
        }
      )
      .pipe(
        map((result: any) => {
          return result;
        })
      );
  }
}
