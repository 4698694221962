import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(amount: number, currencyCode: string = 'EUR', includeSymbol: boolean = true): string {
    // Define the options for formatting
    const options: any = {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };

    // Format the amount using Intl.NumberFormat
    let formattedAmount = new Intl.NumberFormat('en-GB', options).format(amount);

    // Special handling for EUR: adjust the formatting
    if (currencyCode === 'EUR') {
      // Remove the thousands separator (remove commas)
      formattedAmount = formattedAmount.replace(/,/g, '');

      // Replace the decimal point (dot) with a comma
      formattedAmount = formattedAmount.replace('.', ',');

      // Remove the currency symbol if includeSymbol is false
      if (!includeSymbol) {
        formattedAmount = formattedAmount.replace('€', '').trim();
      }
    }

    // For other currencies, the default behavior applies, just removing the symbol if needed
    else if (!includeSymbol) {
      formattedAmount = formattedAmount.replace(/[^\d,.-]/g, '').trim();
    }

    return formattedAmount;
  }
}
