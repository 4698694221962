import { Component, OnInit, ViewChild, ElementRef, OnDestroy, HostListener } from '@angular/core';
import { locale, loadMessages } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';
import * as deLocale from 'date-fns/locale/de/index.js';
import { ReservationService } from '../reservation.service';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { DxSchedulerComponent } from 'devextreme-angular/ui/scheduler';
import { DxContextMenuComponent } from 'devextreme-angular/ui/context-menu';
// import * as events from 'devextreme/events';
import moment from 'moment';
moment.locale('de');
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { differenceInMinutes, subDays, addDays, isSameDay } from 'date-fns';
import { Page, Reservation } from '../reservation.model';
import { AddReservationComponent } from './add-reservation/add-reservation.component';
import { MatSnackBar, MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { AuthenticationService, Constants, CredentialsService, resStatus } from '@app/core';
import { ConfirmModalComponent } from '@app/shared/confirm-modal/confirm-modal.component';
import * as screenfull from 'screenfull';
import { Screenfull } from 'screenfull';
import 'rxjs/add/operator/map';
import { SocketService } from '@app/socket.service';
import { Subscription, Subject } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BreakpointState, BreakpointObserver } from '@angular/cdk/layout';
import { NavService } from '@app/nav.service';
import 'rxjs/add/operator/takeUntil';
import { PendingReservationsComponent } from './pending-reservations/pending-reservations.component';
import { ActivatedRoute } from '@angular/router';
import { BVAddReservationComponent } from './bv-add-reservation/bv-add-reservation.component';
import { ThemeService } from '@app/shell/theme.service';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-reservations',
  templateUrl: './reservations.component.html',
  styleUrls: ['./reservations.component.scss']
})
export class ReservationsComponent implements OnInit, OnDestroy {
  bookSettings: any;
  dateOptions = {
    locale: deLocale
  };
  todayDate: Date = new Date();
  dataSource: any = {};
  priorityData: [];
  typeData: [];
  tablesData: any = [];
  rooms: any = [];
  shifts: any = [];
  temp: any = [];
  currentDate: Date = new Date();
  pendingCounter = 0;
  reservations: any = [];
  tags: any = [];
  allTags: any = [];
  intolerance: any[] = [];
  resAddOns: any[] = [];
  filteredReservations: any[] = [];
  resetTimeFilter = false;
  resStatusList: any[] = [];
  allStatus: any[] = resStatus;
  isFullscreen = false;
  view: String = 'list';
  page: Page = new Page();
  editing: any = {};
  columns = [{ name: 'Uhrzeit' }, { name: 'Gast' }, { name: 'Personen' }, { name: 'Tisch' }, { name: 'Status' }];
  isDoubleClick = 0;
  prevCellData: any = null;
  alerts: any;
  resMenuOrderAlert: any = [];
  activeClient: any;
  reloadInterval: any;
  filter: any = {
    name: '',
    status: null,
    tags: [],
    roomId: null,
    shiftId: null
  };
  selectedRoomId: number;
  schedulerHeight: any = '';
  listHeight = 'initial';
  loadingReservationList: boolean;
  // timeFilter = [
  //   { name: 'Morgen', from: '07:00', to: '11:00' },
  //   { name: 'Mittag', from: '11:00', to: '16:00' },
  //   { name: 'Abend', from: '16:00', to: '24:00' }
  // ];
  timeFilter: any = [];
  selectedTimeFilter: any = [];
  tempRes: any = [];
  showExtraFilters: boolean = false;

  @ViewChild(DxSchedulerComponent, { static: false }) scheduler: DxSchedulerComponent;
  @ViewChild('cellMenu', { static: false }) cellMenu: DxContextMenuComponent;
  @ViewChild('reservationMenu', { static: false }) reservationMenu: DxContextMenuComponent;
  @ViewChild('fullscreenEl', { static: false }) fullscreenEl: ElementRef;
  @ViewChild('buttonBar', { static: false }) buttonBar: ElementRef;
  @ViewChild('roomTabsGroup', { static: false }) roomTabsGroup: any;

  // Context Menu
  contextMenuCellData: any;
  contextMenuAppointmentData: any;
  contextMenuTargetedAppointmentData: any;
  reservationContextMenuItems: any = [];
  cellContextMenuItems: any;
  selectedCellData: any;

  // Edit mode(for mobile touch drag)
  editMode = false;

  // Fake fullscreen
  isFakeFullscreen = false;

  // Layout
  readonly LAYOUT = {
    XXS: 450,
    XS: 768,
    SM: 992,
    MD: 1200,
    LG: 1600,
    XL: 1920,
    XXL: 2560
  };
  layout: number = this.LAYOUT.MD;

  // Sockets
  private _reservationSub: Subscription;
  private _reservationUpdateSub: Subscription;
  private _reservationFileUploadeSub: Subscription;

  private ngUnsubscribe: Subject<any> = new Subject();
  isArchive: boolean = false;
  resId: any;
  reservationListActive: boolean = false;
  activeTheme: string;

  constructor(
    private snackBar: MatSnackBar,
    private reservationService: ReservationService,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    private socketService: SocketService,
    private deviceService: DeviceDetectorService,
    private breakpointObserver: BreakpointObserver,
    private navService: NavService,
    private bottomSheet: MatBottomSheet,
    private credentialsService: CredentialsService,
    private route: ActivatedRoute,
    private themeService: ThemeService
  ) {
    loadMessages(deMessages);
    locale('de');
    this.themeService.activeTheme$.subscribe((theme: any) => {
      this.activeTheme = theme;
    });

    this.reservationService
      .getReservationBookSettings()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((settings: any) => {
        this.bookSettings = settings;
        if (this.bookSettings && Object.keys(this.bookSettings).length > 0) {
          this.bookSettings.startTimeOriginal = this.bookSettings.startTime;
          this.bookSettings.endTimeOriginal = this.bookSettings.endTime;
        }
      });
    this.resId = this.route.snapshot && this.route.snapshot.params ? this.route.snapshot.params.reservationId : '';
    this.page.pageNumber = 0;
    this.page.size = 20;

    // Create context menu
    this.cellContextMenuItems = [
      {
        text: 'Neue Reservierung',
        onItemClick: (e: any) =>
          this.addReservation(
            false,
            this.contextMenuCellData.startDate,
            this.contextMenuCellData.endDate,
            this.contextMenuCellData.groups.tableId
          )
      },
      {
        text: 'Neuer Walk-In',
        onItemClick: () =>
          this.addReservation(
            true,
            this.contextMenuCellData.startDate,
            this.contextMenuCellData.endDate,
            this.contextMenuCellData.groups.tableId,
            true
          )
      }
    ];

    /*
    (screenfull as Screenfull).on('change', () => {
      if ((screenfull as Screenfull).isFullscreen) {
        console.log('fullscreen');
        this.isFullscreen = true;
        // Set height of scheduler to fit window height and make inner table scrollable
        if (this.view === 'timeline') {
          setTimeout(() => {
            // Set scheduler height
            this.schedulerHeight =
              this.fullscreenEl.nativeElement.offsetHeight -
              (this.roomTabsGroup._elementRef.nativeElement.offsetHeight +
                this.buttonBar.nativeElement.offsetHeight -
                10);
            this.scheduler.instance.repaint();
          }, 200);
        } else {
          // Set list height
          setTimeout(() => {
            this.listHeight = window.outerHeight - this.buttonBar.nativeElement.offsetHeight + 'px';
            this.table.recalculate();
          }, 200);
        }
      } else {
        this.isFullscreen = false;
        this.schedulerHeight = '';
        if (this.scheduler) {
          this.scheduler.instance.repaint();
        }
      }
    });
    */
  }

  async ngOnInit() {
    if (this.resId) {
      this.currentDate = await this.reservationService
        .getDateofReservation(this.resId)
        .toPromise()
        .then((data: any) => {
          this.currentDate = new Date(data.reservedFor);
          return new Date(data.reservedFor);
        });
    }

    this.authService
      .getActiveClient()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((clientData: any) => {
        this.activeClient = clientData;
        // (<any>window).ga(
        //   'set',
        //   'title',
        //   'Reservierungsbuch - ' + this.activeClient.name + ' ' + this.activeClient.location
        // );
        // this.socketService.joinClient(this.activeClient.id);
      });
    this.getResTimeFilter();
    this.getRooms();
    this.getShifts();
    this.getAllTags();
    this.getAlerts();
    this.reservationService.getIncompatibilities().subscribe(intolerance => {
      this.intolerance = intolerance.data;
    });
    this.reservationService.getReservationAddOnTemplates().subscribe(resAddOns => {
      this.resAddOns = resAddOns;
    });
    this.getAllReservations();

    document.addEventListener('fullscreenchange', e => {
      if (document && document['fullscreenElement']) {
        this.isFullscreen = true;
        // Set height of scheduler to fit window height and make inner table scrollable
        if (this.view === 'timeline') {
          setTimeout(() => {
            // Set scheduler height
            this.schedulerHeight =
              window.innerHeight -
              (this.roomTabsGroup._elementRef.nativeElement.offsetHeight +
                this.buttonBar.nativeElement.offsetHeight -
                10);
            setTimeout(() => {
              this.scheduler.instance.repaint();
            }, 100);
          }, 200);
        } else {
          // Set list height
          setTimeout(() => {
            this.listHeight = window.innerHeight + 'px';
            // this.table.recalculate();
          }, 200);
        }
      } else {
        this.isFullscreen = false;
        this.schedulerHeight = '';
        if (this.scheduler) {
          this.scheduler.instance.repaint();
        }
      }
    });

    // Get socket messages
    this._reservationSub = this.socketService.newReservation.takeUntil(this.ngUnsubscribe).subscribe((res: any) => {
      // Check if reservation is from current day in view
      if (moment(this.currentDate).isSameOrAfter(res.reservedFor, 'day')) {
        this.snackBar.open('Neue Reservierung eingetroffen', '', {
          duration: 2000,
          panelClass: ['snackbar-info']
        });
        if (this.view === 'list') {
          this.getAllReservations();
        } else {
          this.dataSource.reload();
        }
      }
    });

    this._reservationUpdateSub = this.socketService.updateReservation
      .takeUntil(this.ngUnsubscribe)
      .subscribe(async (res: any) => {
        // Check if reservation is from current day in view
        if (moment(this.currentDate).isSameOrAfter(res.reservedFor, 'day')) {
          let snackMsg = '';
          if (res.guestData) {
            snackMsg = 'Reservierung von ' + res.guestData.name + ' wurde aktualisiert';
          } else {
            snackMsg = 'Walk-In um ' + moment(res.reservedFor).format('HH:mm') + ' Uhr wurde aktualisiert';
          }
          this.snackBar.open(snackMsg, '', {
            duration: 2000,
            panelClass: ['snackbar-info']
          });
          if (this.view === 'list') {
            if (!res.calledFunction || res.calledFunction != 'change-status') {
              this.getAllReservations();
            } else {
              await new Promise<void>(resolve => {
                this.setReservationListValues();
                resolve();
              });
            }
          } else {
            this.dataSource.reload();
          }
        }
      });

    this._reservationFileUploadeSub = this.socketService.uploadFiles
      .takeUntil(this.ngUnsubscribe)
      .subscribe((res: any) => {
        // Check if reservation is from current day in view
        if (moment(this.currentDate).isSameOrAfter(res.reservedFor, 'day')) {
          if (this.view === 'list') {
            this.getAllReservations();
          } else {
            this.dataSource.reload();
          }
        }
      });

    // Setting up breakpoint mechanism
    const breakpoints = Object.keys(this.LAYOUT).map(k => this.LAYOUT[k]);
    breakpoints.forEach((maxWidth, index) => {
      const minWidth = index > 0 ? breakpoints[index - 1] : 0;
      this.breakpointObserver
        .observe([`(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`])
        .takeUntil(this.ngUnsubscribe)
        .subscribe((state: BreakpointState) => {
          if (!state.matches) {
            return;
          }
          this.layout = maxWidth;
        });
    });
    this.getAllStatus();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this._reservationSub.unsubscribe();
    this._reservationFileUploadeSub.unsubscribe();
  }

  getAllStatus() {
    this.reservationService
      .getResStatus()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resStatusList: any) => {
        this.resStatusList = resStatusList;
        this.resStatusList.forEach((item: any) => {
          item['type'] = 'single';
        });
        this.allStatus = this.allStatus.concat(this.resStatusList);
      });
  }

  getAlerts() {
    return this.reservationService
      .getAlerts('staff', moment(this.currentDate).format('YYYY-MM-DD'))
      .takeUntil(this.ngUnsubscribe)
      .subscribe((alerts: any) => {
        this.alerts = alerts;
      });
  }

  closeAlert(alert: any) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  closeResMenuOrderAlert(alert: any) {
    this.resMenuOrderAlert.splice(this.alerts.indexOf(alert), 1);
  }

  changeView(val: string) {
    if (val === 'list') {
      if (this.listHeight === '') {
        // Set list height
        setTimeout(() => {
          this.listHeight = window.innerHeight - this.buttonBar.nativeElement.offsetHeight + 'px';
        }, 100);
      }
      this.getAllReservations();
    } else if (val === 'timeline') {
      // Set height of scheduler to fit window height and make inner table scrollable
      if (this.schedulerHeight === '') {
        setTimeout(() => {
          this.schedulerHeight =
            window.innerHeight -
            (this.roomTabsGroup._elementRef.nativeElement.offsetHeight +
              this.buttonBar.nativeElement.offsetHeight -
              10);
          setTimeout(() => {
            if (this.scheduler) {
              this.scheduler.instance.repaint();
            }
          }, 100);
        }, 100);
      }

      this.tablesData = this.rooms[0] ? this.rooms[0].tables : [];
      this.selectedRoomId = this.rooms[0] ? this.rooms[0].id : null;
      this.dataSource = new DataSource({
        store: new CustomStore({
          load: options => this.getTimelineReservations(options),
          update: (key, values) => this.updateReservation(key, values)
        })
      });

      // this.dataSource.reload();
    } else if (val === 'tableplan') {
      this.dataSource = new DataSource({
        store: new CustomStore({
          load: () => this.getAllReservations(),
          update: (key, values) => this.updateReservation(key, values)
        })
      });
    }
  }

  getTimelineReservations(options: any) {
    options.roomId = this.selectedRoomId;
    return this.reservationService
      .getTimelineReservations(options)
      .toPromise()
      .then((data: any) => {
        data = this.setStatusInfo('timeline', data);
        return data;
      });
  }

  updateReservation(key: any, values: any) {
    if (key.tableId !== values.tableId) {
      // move reservation to other table
      values.startDate = key.startDate;
      values.reservedFor = key.reservedFor;
      values.endDate = key.endDate;
      const minutes = differenceInMinutes(values.endDate, values.startDate);
      values.stayTime = minutes;
      values.oldTableId = key.tableId;
      console.log(480, 'new table', key, values);
    } else {
      // move reservation on the same table
      // values.reservedFor = values.startDate;
      values.reservedFor = key.reservedFor;
      const minutes = differenceInMinutes(values.endDate, values.startDate);
      values.stayTime = minutes;
      values.oldTableId = key.tableId;
      console.log(480, 'same table', key, values);
    }

    return this.reservationService
      .editReservation(values)
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }

  getRooms() {
    return this.reservationService
      .getRooms(false)
      .toPromise()
      .then((data: any) => {
        if (data) {
          this.rooms = data;
          this.tablesData = this.rooms && this.rooms.length ? this.rooms[0].tables : [];
          let allTables: any = [];
          this.rooms.map((item: any) => {
            allTables.push(...item.tables);
          });
          let allRooms = { ...this.rooms[0] };
          allRooms.id = 1000;
          allRooms.name = 'All';
          allRooms.tables = allTables;
          this.rooms.push(allRooms);
        }
      });
  }

  getShifts() {
    this.reservationService
      .getShifts()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((shifts: any) => {
        this.shifts = shifts;
      });
  }

  async selectedTabChange(e: any) {
    this.tablesData = this.rooms[e.index].tables;
    this.selectedRoomId = this.rooms[e.index].id;
    this.dataSource.reload();
  }

  onInitialized(e: any) {
    setTimeout(() => {
      const scroll = e.component.getWorkSpaceScrollable();
      const scrollInstance = scroll.instance();
      scrollInstance.beginUpdate();
      scrollInstance.option('useNative', false);
      scrollInstance.endUpdate();

      const currentTimeHour = +moment(new Date()).format('HH') - 2;
      if (this.scheduler) {
        this.scheduler.instance.scrollToTime(currentTimeHour, 0);
      }
    });
  }

  onContentReady(e: any) {
    const scroll = e.component.getWorkSpaceScrollable();
    const scrollInstance = scroll.instance();
    scrollInstance.beginUpdate();
    scrollInstance.option('useNative', false);
    scrollInstance.endUpdate();
  }

  onAppointmentRendered(e: any) {
    /*
    events.on(e.appointmentElement, 'dxdragstart', () => {
      console.log('dra');
    });
    */
    /*
    const transform = this.getTranslateXY(e.appointmentElement);
    e.appointmentElement.style.height = '70px';
    if (transform) {
      e.appointmentElement.
      style.transform = 'translate(' + transform.x + 'px,' + (transform.y - 25) + 'px)';
    }
    */
  }

  onAppointmentUpdating(e: any) {
    if (e.oldData.locked) {
      e.cancel = true;
      this.snackBar.open('Reservierung ist gesperrt', '', {
        duration: 2000,
        panelClass: ['snackbar-error']
      });
    }
  }

  onCellContextMenu(e: any) {
    this.contextMenuCellData = e.cellData;
    // Get start and end time of all selected cells
    this.contextMenuCellData.startDate = this.scheduler.selectedCellData[0].startDate;
    this.contextMenuCellData.endDate = this.scheduler.selectedCellData[
      this.scheduler.selectedCellData.length - 1
    ].endDate;
  }

  onAppointmentContextMenu(e: any) {
    this.contextMenuAppointmentData = e.appointmentData;
    this.contextMenuTargetedAppointmentData = e.targetedAppointmentData;

    this.onReservationContextMenuShowing(e);
  }

  // Called before the context menu for a reservation is opened
  // so build the menu based on the status
  onReservationContextMenuShowing(e: any) {
    const basicMenuItems: any = [
      {
        text: 'Bearbeiten',
        onItemClick: () => this.editReservation(this.contextMenuTargetedAppointmentData)
      }
    ];
    let contextMenuItems: any = [];
    let roomsContextMenu: any = [];

    const allStatus = this.allStatus.slice(1);

    const confirmedStatus = allStatus.find(status => status.value === 'confirmed');
    const arrivedStatus = allStatus.find(status => status.value === 'arrived');
    const placedStatus = allStatus.find(status => status.value === 'placed');
    const noShowStatus = allStatus.find(status => status.value === 'noShow');
    const canceledStatus = allStatus.find(status => status.value === 'canceled');
    const finishedStatus = allStatus.find(status => status.value === 'finished');

    if (e.appointmentData) {
      if (e.appointmentData.status === 'confirmed') {
        contextMenuItems = [
          { text: 'Status ändern', beginGroup: true, disabled: true },
          {
            ...arrivedStatus,
            text: arrivedStatus.label,
            onItemClick: () => this.updateStatusTimeline('arrived', this.contextMenuTargetedAppointmentData.id)
          },
          {
            ...placedStatus,
            text: placedStatus.label,
            onItemClick: () => this.updateStatusTimeline('placed', this.contextMenuTargetedAppointmentData.id)
          },
          {
            ...finishedStatus,
            text: finishedStatus.label,
            onItemClick: () => this.updateStatusTimeline('finished', this.contextMenuTargetedAppointmentData.id)
          },
          {
            ...noShowStatus,
            text: noShowStatus.label,
            onItemClick: () => this.updateStatusTimeline('noShow', this.contextMenuTargetedAppointmentData.id)
          },
          {
            ...canceledStatus,
            text: canceledStatus.label,
            onItemClick: () => this.updateStatusTimeline('canceled', this.contextMenuTargetedAppointmentData.id)
          }
        ];
      }

      if (e.appointmentData.status === 'pending') {
        contextMenuItems = [
          { text: 'Status ändern', beginGroup: true, disabled: true },
          {
            ...confirmedStatus,
            text: confirmedStatus.label,
            onItemClick: () => this.updateStatusTimeline('confirmed', this.contextMenuTargetedAppointmentData.id)
          },
          {
            ...canceledStatus,
            text: canceledStatus.label,
            onItemClick: () => this.updateStatusTimeline('canceled', this.contextMenuTargetedAppointmentData.id)
          }
        ];
      }

      if (e.appointmentData.status === 'placed') {
        contextMenuItems = [
          { text: 'Status ändern', beginGroup: true, disabled: true },
          {
            ...finishedStatus,
            text: finishedStatus.label,
            onItemClick: () => this.updateStatusTimeline('finished', this.contextMenuTargetedAppointmentData.id)
          }
        ];
      }

      if (!contextMenuItems.length) {
        contextMenuItems = [
          { text: 'Status ändern', beginGroup: true, disabled: true },
          ...allStatus.map(status => ({
            text: status.label,
            color: status.color,
            icon: status.icon,
            onItemClick: () => this.updateStatusTimeline(status.value, this.contextMenuTargetedAppointmentData.id)
          }))
        ];
      } else {
        contextMenuItems = [
          ...contextMenuItems,
          ...this.resStatusList.map(status => ({
            text: status.label,
            color: status.color,
            icon: status.icon,
            onItemClick: () => this.updateStatusTimeline(status.value, this.contextMenuTargetedAppointmentData.id)
          }))
        ];
      }

      if (e.appointmentData.paymentTransaction) {
        contextMenuItems.map((item: any, key: any) => {
          if (item.text == 'Storniert') {
            contextMenuItems.splice(key, 1);
          }
        });
      }

      if (this.rooms.length > 1) {
        roomsContextMenu = [
          {
            text: 'Verschieben...',
            beginGroup: true,
            items: []
          }
        ];

        for (let index = 0; index < this.rooms.length; index++) {
          const room = this.rooms[index];
          roomsContextMenu[0].items.push({
            text: room.name,
            items: this.roomTablesContextMenu(index, this.contextMenuTargetedAppointmentData)
          });
        }
      }
    }

    // Merge arrays
    this.reservationMenu.instance.option('items', [...basicMenuItems, ...contextMenuItems, ...roomsContextMenu]);
  }

  roomTablesContextMenu(roomIndex: number, reservation: any) {
    const tablesContextMenu: any = [];
    for (let index = 0; index < this.rooms[roomIndex].tables.length; index++) {
      const table = this.rooms[roomIndex].tables[index];
      tablesContextMenu.push({
        text: table.name,
        onItemClick: () => {
          const values = reservation;
          if (values.tables && values.tables.length > 1) {
            values.isTablePlan = values.isTablePlan.replace(values.tableId, table.id);
            const replaceTableIndex = values.tables.findIndex((s: any) => s.id === values.tableId);
            values.tables.splice(replaceTableIndex, 1);
            values.tables.push(table);
            values.tableId = table.id;
          } else {
            values.tableId = table.id;
            values.isTablePlan = table.id;
            values.tables = [table];
          }
          return this.reservationService
            .editReservation(values)
            .toPromise()
            .then((data: any) => {
              return data;
            });
        }
      });
    }

    return tablesContextMenu;
  }

  onContextMenuItemClick(e: any) {
    e.itemData.onItemClick(e.itemData);
  }

  getTranslateXY(obj: any) {
    const style = obj.style,
      transform = style.transform || style.webkitTransform;
    const match = transform.match(/translate\((-?\d+(?: \.\d*)?)px, (-?\d+(?:\.\d*)?)px\)/);

    if (!match) {
      return;
    }

    const transObj = {
      x: parseFloat(match[1]),
      y: parseFloat(match[2])
    };
    return transObj;
  }

  openFullscreen() {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    // if (isSafari) {
    this.fakeFullscreen();
    // } else {
    //   (screenfull as Screenfull).request(this.fullscreenEl.nativeElement);
    //   screen.orientation.lock('landscape');
    // }
    /*
    console.log('fs', this.fullscreenEl);
    this.fullscreenEl.nativeElement.requestFullscreen();
    */
  }

  openCreateBottomSheet() {
    const bottomSheetRef = this.bottomSheet.open(AddBottomSheetComponent);
    bottomSheetRef
      .afterDismissed()
      .takeUntil(this.ngUnsubscribe)
      .subscribe(data => {
        if (data && data === 'reservation') {
          this.addReservation();
        } else if (data && data === 'walkin') {
          this.addReservation(true);
        }
      });
  }

  closeFullscreen() {
    if (this.isFakeFullscreen) {
      this.isFullscreen = false;
      this.isFakeFullscreen = false;
      this.navService.showToolbar$.next(true);
      this.schedulerHeight = '';
      this.listHeight = 'auto';
      if (this.scheduler) {
        this.scheduler.instance.repaint();
      }
    } else {
      (screenfull as Screenfull).exit();
    }
  }

  fakeFullscreen() {
    this.navService.appDrawer.close();
    this.navService.showToolbar$.next(false);
    this.isFakeFullscreen = true;
    this.isFullscreen = true;

    // Set height of scheduler to fit window height and make inner table scrollable
    if (this.view === 'timeline') {
      setTimeout(() => {
        // Set scheduler height
        this.schedulerHeight =
          window.innerHeight -
          (this.roomTabsGroup._elementRef.nativeElement.offsetHeight + this.buttonBar.nativeElement.offsetHeight - 10);
        setTimeout(() => {
          this.scheduler.instance.repaint();
        }, 100);
      }, 50);
    } else {
      // Set list height
      setTimeout(() => {
        this.listHeight = window.innerHeight - this.buttonBar.nativeElement.offsetHeight - 15 + 'px';
        // this.table.recalculate();
      }, 50);
    }
  }

  translateStatus(status: string) {
    switch (status) {
      case 'confirmed':
        return 'Bestätigt';
        break;
      case 'canceled':
        return 'Storniert';
        break;
      case 'noShow':
        return 'No Show';
        break;
      case 'arrived':
        return 'Angekommen';
        break;
      case 'placed':
        return 'Platziert';
        break;
      case 'pending':
        return 'Ausstehend';
        break;
      case 'waiting':
        return 'Warteliste';
        break;
      case 'finished':
        return 'Fertig';
        break;
      case 'blocked':
        return 'Ausstehende Zahlung';
        break;
      default:
        break;
    }
  }

  isIntermediateTime(time: string) {
    const array = time.split(':');

    if (array[1] === '15' || array[1] === '30' || array[1] === '45') {
      return true;
    }

    return;
  }

  isIntermediateDate(date: any) {
    const dateString = new Date(date);

    if (dateString.getMinutes() === 0) {
      return;
    }

    return true;
  }

  onAppointmentClick(event: any) {
    event.cancel = true;
    let appendContainer = 'body';
    if (this.isFullscreen) {
      appendContainer = 'dx-scheduler';
    }
    const modalComponent =
      this.activeClient.id == Constants.BVClient ? BVAddReservationComponent : AddReservationComponent;
    const modalRef = this.modalService.open(modalComponent, {
      size: 'lg',
      container: appendContainer
    });

    modalRef.componentInstance.reservation = {
      ...event.appointmentData
    };
    modalRef.componentInstance.isWalkIn = !event.appointmentData.gastId ? true : false;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (this.view === 'list') {
        this.getAllReservations();
      } else {
        this.dataSource.reload();
      }
      this.snackBar.open('Reservierung erfolgreich geändert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  onCellClick(event: any) {
    if (this.isDoubleClick === 1) {
      this.prevCellData = event.cellData;
    }
    this.isDoubleClick++;
    setTimeout(() => {
      if (this.isDoubleClick === 2) {
        if (
          event.cellData.startDate === this.prevCellData.startDate &&
          event.cellData.endDate === this.prevCellData.endDate
        ) {
          this.addReservation(false, event.cellData.startDate, null, event.cellData.groups.tableId);
        }
      } else if (this.isDoubleClick === 1) {
        // Do nothing
      }
      this.isDoubleClick = 0;
      this.prevCellData = null;
    }, 300);
  }

  editReservation(reservation: Reservation) {
    let appendContainer = 'body';
    if (this.isFullscreen) {
      appendContainer = '.fullscreen-element';
    }
    const modalComponent =
      this.activeClient.id == Constants.BVClient ? BVAddReservationComponent : AddReservationComponent;
    const modalRef = this.modalService.open(modalComponent, {
      size: 'lg',
      container: appendContainer
    });

    modalRef.componentInstance.reservation = reservation;
    modalRef.componentInstance.isWalkIn = !reservation.gastId ? true : false;
    modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
      if (this.view === 'list') {
        this.getAllReservations();
      } else {
        this.dataSource.reload();
      }
      this.snackBar.open('Reservierung erfolgreich geändert', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      modalRef.close();
    });
  }

  addReservation(
    walkin: boolean = false,
    startDate: any = null,
    endDate: any = null,
    tableId: any = null,
    instantSave: boolean = false
  ) {
    if (instantSave && walkin) {
      // If instantSave is true, add the reservation without showing modal window
      const momentEndDate = moment(endDate);
      const duration = moment.duration(momentEndDate.diff(startDate));
      const reservation = {
        stayTime: duration.asMinutes(),
        guestCount: 2,
        reservedFor: startDate,
        time: moment(startDate).format('HH:mm'),
        status: 'placed',
        isTablePlan: tableId
      };
      this.reservationService
        .addReservation(reservation)
        .takeUntil(this.ngUnsubscribe)
        .subscribe(res => {
          this.dataSource.reload();
          this.snackBar.open('Walk-In erfolgreich erstellt', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        });
    } else {
      let appendContainer = 'body';
      if (this.isFullscreen) {
        appendContainer = '.fullscreen-element';
      }
      const modalComponent =
        this.activeClient.id == Constants.BVClient ? BVAddReservationComponent : AddReservationComponent;
      const modalRef = this.modalService.open(modalComponent, {
        size: 'lg',
        container: appendContainer
      });

      modalRef.componentInstance.isWalkIn = walkin;
      modalRef.componentInstance.defaultDate = walkin ? new Date() : this.currentDate;
      modalRef.componentInstance.defaultTable = tableId;
      modalRef.componentInstance.startDate = startDate;
      modalRef.componentInstance.endDate = endDate;
      let table = this.tablesData.find((s: any) => s.id === tableId);
      if (table) {
        table.isFree = true;
        modalRef.componentInstance.selectedTablePlanTable = table;
      }

      modalRef.componentInstance.passEntry.takeUntil(this.ngUnsubscribe).subscribe((receivedEntry: any) => {
        if (this.view === 'list') {
          this.getAllReservations();
        } else {
          this.dataSource.reload();
        }
        this.snackBar.open('Reservierung erfolgreich erstellt', '', {
          duration: 2000,
          panelClass: ['snackbar-success']
        });
        modalRef.close();
      });
    }
  }

  async getAllReservations() {
    this.loadingReservationList = true;
    this.tags = [];
    this.resetTimeFilter = false;
    await this.reservationService
      .getAllReservationsBook(this.currentDate, this.currentDate, this.page.pageNumber)
      .toPromise()
      .then(async (data: any) => {
        if (data) {
          this.isArchive = data.isArchive;
          this.editing = {}; // Reset editing because of new rows
          this.temp = [...data.reservations];
          this.tempRes = this.temp;
          this.pendingCounter = data.pendingCounter;
          this.reservations = data.reservations;
          await new Promise<void>(resolve => {
            this.setReservationListValues();
            resolve();
          });
          this.searchReservation();
          this.loadingReservationList = false;
        }
      });

    //  If not tags, add no tags option
    if (!this.tags.length) {
      this.tags = [
        {
          id: null,
          label: 'Keine Tags für diesen Tag',
          disabled: true
        }
      ];
    }
  }

  setReservationListValues() {
    let resAllMenuOrderTags: any = [];
    let resMenuOrderAlert: any = [];
    if (this.reservations.length) {
      this.reservations.forEach((reservation: any) => {
        if (reservation.tags && this.isArchive === false) {
          reservation.tags = reservation.tags.filter(
            (tag: any) => tag.type !== 'res-menu-order' && tag.type !== 'ticket'
          );
          if (reservation.tags.length) {
            reservation.tags.forEach((tag: any) => {
              if (this.tags.findIndex((x: any) => x.id === tag.id) === -1) {
                tag.selected = false;
                this.tags = [...this.tags, tag];
                // this.tags.push(tag);
              }
            });
          }
        }
        if (
          reservation &&
          reservation.guestData &&
          reservation.guestData.intolerance &&
          reservation.guestData.intolerance != 'null'
        ) {
          reservation.guestData.intoleranceValue = this.getIntolerance(reservation.guestData.intolerance);
          console.log('test', reservation.guestData.intoleranceValue, reservation.guestData.intolerance);
        }
        if (reservation && reservation.addOns) {
          reservation.addOns = this.getResAddOns(reservation.addOns);
        }
        let resMenuOrderTags: any = [];
        if (
          reservation.status != 'canceled' &&
          reservation.resMenuOrdersItem &&
          Object.keys(reservation.resMenuOrdersItem).length > 0
        ) {
          let i = 1;
          Promise.all(
            reservation.resMenuOrdersItem.map((item: any) => {
              resMenuOrderTags[item.resAnswers.answer] = {
                count:
                  ((resMenuOrderTags[item.resAnswers.answer] && resMenuOrderTags[item.resAnswers.answer]['count']) ||
                    0) + 1,
                color: item.resAnswers.color,
                preOrderName: item.menuOrder && item.menuOrder.name ? item.menuOrder.name : ''
              };
              resAllMenuOrderTags[item.menuOrder.name + ':' + item.resAnswers.answer] =
                (resAllMenuOrderTags[item.menuOrder.name + ':' + item.resAnswers.answer] || 0) + 1;
            })
          );
          Object.keys(resMenuOrderTags).map((item: any) => {
            reservation.tags.push({
              id: null,
              label: `${resMenuOrderTags[item]['count']} * ${item}`,
              color: resMenuOrderTags[item]['color'] ? resMenuOrderTags[item]['color'] : '#ffb6c1',
              type: 'res-menu-order'
            });
          });
        }

        if (reservation.ticketOrder && Object.keys(reservation.ticketOrder).length > 0) {
          const ticketCategories: any = {};
          reservation.ticketOrder.tickets.forEach((ticket: any) => {
            const ticketName = ticket.ticket.name;
            const subCategoryName = ticket.subCategory.name;

            if (!ticketCategories[ticketName]) {
              ticketCategories[ticketName] = {};
            }

            ticketCategories[ticketName][subCategoryName] = (ticketCategories[ticketName][subCategoryName] || 0) + 1;
          });

          const formattedTickets = Object.entries(ticketCategories).map(([ticketName, subCategoryCounts]) => {
            const formattedCategories = Object.entries(subCategoryCounts)
              .map(([name, count]) => `${count}*${name}`)
              .join(', ');
            return `${ticketName}: ${formattedCategories}`;
          });
          reservation.tags.push({
            id: null,
            label: formattedTickets,
            color: '#f3d425',
            textColor: '#454545',
            type: 'ticket'
          });
        }
      });
    }

    Object.keys(resAllMenuOrderTags).map((item: any) => {
      let preOrderName = item.split(':');
      if (!resMenuOrderAlert[preOrderName[0]]) {
        resMenuOrderAlert[preOrderName[0]] = [];
      }
      resMenuOrderAlert[preOrderName[0]].push(`${resAllMenuOrderTags[item]} * ${preOrderName[1]}`);
    });
    this.resMenuOrderAlert = [];
    if (resMenuOrderAlert && Object.keys(resMenuOrderAlert).length > 0) {
      Object.keys(resMenuOrderAlert).map((item: any) => {
        this.resMenuOrderAlert.push({ info: `${item} : ${resMenuOrderAlert[item].join(', ')}` });
      });
    }
    return true;
  }

  getIntolerance(intolerance: string): string {
    const intolerances = JSON.parse(intolerance);
    let result: any = [];
    for (let i = 0; i < intolerances.length; i++) {
      const element = intolerances[i];
      const intoleranceRes = this.intolerance.find(el => {
        return element == el.id;
      });
      result.push(intoleranceRes.name);
    }
    return result;
  }
  getResAddOns(addOnsString: string): string {
    const addOns = addOnsString.split(',');
    let result: any = [];
    for (let i = 0; i < addOns.length; i++) {
      const element = addOns[i];
      const addOnsRes = this.resAddOns.find(el => {
        return element == el.id;
      });
      if (addOnsRes && addOnsRes.title) {
        result.push(addOnsRes.title);
      }
    }
    return result;
  }

  prevDay() {
    this.currentDate = subDays(this.currentDate, 1);
    this.changeDate();
  }

  nextDay() {
    this.currentDate = addDays(this.currentDate, 1);
    this.changeDate();
  }

  today() {
    this.currentDate = new Date();
    this.changeDate();
    this.resetTimeFilter = true;
  }

  changeDate() {
    this.getAlerts();
    this.getAllReservations();
  }

  onReservationListChange(event: any) {}

  searchReservation() {
    // filter our data
    const filterKeys = Object.keys(this.filter);
    const temp = this.temp.filter((eachObj: any) => {
      return filterKeys.every(eachKey => {
        const that = this;
        if (!that.filter[eachKey] || !that.filter[eachKey].toString().length) {
          if (
            (eachObj.status == 'blocked' && that.filter['status'] !== 'blocked') ||
            (!this.bookSettings.showCanceledRes && eachObj.status == 'canceled' && that.filter['status'] !== 'canceled')
          ) {
            return false;
          }
          return true; // passing an empty filter means that filter is ignored.
        }
        // Search for guest name
        if (eachKey === 'name') {
          if (!eachObj.guestData) {
            return false;
          }
          if (this.activeClient.id == Constants.BVClient) {
            return (
              (eachObj.guestData[eachKey]
                ? eachObj.guestData[eachKey].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                : false) ||
              (eachObj.guestData['firstName']
                ? eachObj.guestData['firstName'].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                : false) ||
              (eachObj.guestData['company']
                ? eachObj.guestData['company'].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                : false) ||
              (eachObj.guestData['email']
                ? eachObj.guestData['email'].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                : false) ||
              (eachObj.bavarianData['orderId']
                ? eachObj.bavarianData['orderId'].toString().includes(that.filter[eachKey])
                : false)
            );
          } else {
            return (
              (eachObj.guestData[eachKey]
                ? eachObj.guestData[eachKey].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                : false) ||
              (eachObj.guestData['firstName']
                ? eachObj.guestData['firstName'].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                : false) ||
              (eachObj.guestData['company']
                ? eachObj.guestData['company'].toLowerCase().includes(that.filter[eachKey].toLowerCase())
                : false) ||
              (eachObj.id ? eachObj.id.toString().includes(that.filter[eachKey].replace('#', '')) : false)
            );
          }
        }
        // Search for room
        if (eachKey === 'roomId') {
          if (!eachObj.tables || !eachObj.tables.length) {
            if (eachObj.room && eachObj.room.length) {
              let rooms = Array.isArray(eachObj.room) ? eachObj.room : [eachObj.room];
              return rooms.find((x: any) => x.id === that.filter[eachKey]) ? true : false;
            } else {
              return false;
            }
          }
          return eachObj.tables.find((x: any) => x.roomId === that.filter[eachKey]) ? true : false;
        }
        // Search for tags
        if (eachKey === 'tags') {
          if (!eachObj[eachKey].length) {
            return false;
          }
          return eachObj[eachKey].some((tag: any) => {
            return that.filter[eachKey].filter((e: any) => e.id === tag.id).length > 0;
          });
        }

        // Search for status
        if (eachKey === 'status') {
          if (that.filter[eachKey] === 'active') {
            const statusArray = ['confirmed', 'arrived', 'placed'];
            return statusArray.some((st: any) => {
              return st.includes(eachObj[eachKey]);
            });
          }
          return that.filter[eachKey].includes(eachObj[eachKey]);
        }
        // Search for shifts
        if (eachKey === 'shiftId') {
          if (!eachObj[eachKey]) {
            return false;
          }
          return eachObj[eachKey] === that.filter[eachKey] ? true : false;
        }
        // Other
        return that.filter[eachKey].includes(eachObj[eachKey]);
      });
    });
    // update the rows
    this.reservations = temp;
    this.tempRes = temp;
    this.filterReservationWithTime();
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  getAllTags() {
    this.reservationService
      .getTags()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((tags: any) => {
        this.allTags = tags;
      });
  }

  updateValue(event: any, cell: any, rowIndex: number, reservationId: number) {
    this.editing[rowIndex + '-' + cell] = false;
    this.reservations[rowIndex][cell] = event.target.value;
    this.reservations = [...this.reservations];
    const index = this.reservations.findIndex((x: any) => x.id === reservationId);
    this.reservationService
      .editReservation(this.reservations[index])
      .takeUntil(this.ngUnsubscribe)
      .subscribe(() => {});
  }

  updateStatusTimeline(status: string, reservationId: number) {
    if (this.bookSettings.confirmStatusChange) {
      const modalRef = this.modalService.open(ConfirmModalComponent);
      modalRef.componentInstance.title = 'Status ändern';
      modalRef.componentInstance.message = `Sind Sie sicher dass Sie den Status auf "${this.translateStatus(
        status
      )}" setzen möchten?`;
      modalRef.componentInstance.showInfo = false;
      modalRef.componentInstance.buttonText = 'Ja';

      modalRef.result.then(
        (result: any) => {
          if (result === 'ok') {
            this.updateStatusTimelineHelper(status, reservationId);
          }
        },
        () => {}
      );
    } else {
      this.updateStatusTimelineHelper(status, reservationId);
    }
  }

  async updateStatusTimelineHelper(status: string, reservationId: number) {
    const index = await this.dataSource._items.findIndex((x: any) => x.id === reservationId);
    const reservData: any = { ...this.dataSource._items[index] };
    reservData.status = status;
    if (reservData.guestData) {
      reservData.guestData.guestInform = true;
    }
    reservData.guestCount = reservData.peopleCount;
    this.reservationService
      .editReservation(reservData)
      .takeUntil(this.ngUnsubscribe)
      .subscribe(
        () => {
          this.dataSource.reload();
          this.snackBar.open('Status wurde erfolgreich geändert', '', {
            duration: 2000,
            panelClass: ['snackbar-success']
          });
        },
        err => {
          this.snackBar.open(`Status konnte nicht geändert werden (${err.error.msg})`, '', {
            duration: 2000,
            panelClass: ['snackbar-error']
          });
        }
      );
  }

  isToday() {
    return isSameDay(new Date(), this.currentDate);
  }

  async reservationsFiltered(e: any) {
    this.filteredReservations = e;
  }

  downloadPdf() {
    const doc = new jsPDF('p', 'mm', 'a4');
    console.log(this.reservations, this.filteredReservations);
    // const reservations = this.table.bodyComponent.temp ? this.table.bodyComponent.temp : this.reservations;
    // const reservations = this.reservations;
    let reservations: any = [];
    this.reservations.map((item: any) => {
      if (this.filteredReservations.includes(item.id)) {
        reservations.push(item);
      }
    });
    const reservationsArray = JSON.parse(JSON.stringify(reservations)); // clone ([...reservations] not working?)
    const parsedReservations = reservationsArray
      .filter((res: any) => res.status !== 'canceled')
      .map((res: any) => {
        res.reservedFor = moment(res.reservedFor).format('HH:mm') + ' - ' + moment(res.endDate).format('HH:mm'); // + ' Uhr';
        if (res.guestData && typeof res.guestData === 'object') {
          res.guestName = res.guestData.company
            ? res.guestData.company + ' (' + res.guestData.name + ')'
            : res.guestData.name;
          res.guestName += !res.guestData.company && res.guestData.firstName ? ', ' + res.guestData.firstName : '';
        } else {
          res.guestName = 'Walk-In';
        }
        if (res.notes) {
          res.guestName = res.guestName + '\nAdmin-Hinweis:' + res.notes;
        }
        if (res.msg) {
          res.guestName = res.guestName + '\nGast-Hinweis:' + res.msg;
        }
        const tables: any = [];
        res.tables.forEach((tableData: any) => {
          tables.push(tableData.name);
        });
        // res.tablesString = tables.join(',');
        res.tablesString = '';
        let i = 0;
        tables.forEach((t: string) => {
          i++;
          res.tablesString += t + ',';
          if (i === 4) {
            res.tablesString += '\n';
            i = 0;
          }
        });

        if (!res.tablesString || res.tablesString == '') {
          if (res.room) {
            if (res.room.name) {
              res.tablesString = res.room.name;
            }
          }
        }

        res.peopleCount = res.peopleCount + ' ';

        if (res.highChairs && res.children) {
          res.peopleCount = res.peopleCount + ' (K: ' + res.children + ', HS: ' + res.highChairs + ')';
        } else {
          if (res.children) {
            res.peopleCount += '(K: ' + res.children + ')';
          }
          if (res.highChairs) {
            res.peopleCount += '(HS: ' + res.highChairs + ')';
          }
        }

        const tags: any = [];
        res.tags.forEach((tagData: any) => {
          tags.push(tagData.label);
        });
        res.tagsString = tags.join(',');

        //res.status = this.translateStatus(res.status);
        console.log(res);
        return res;
      });

    const width = doc.internal.pageSize.getWidth();
    const printDate = 'Druckdatum : ' + moment().format('DD.MM.YYYY');
    const txtWidth = doc.getTextWidth(printDate);
    doc.text(width - 15 - txtWidth, 10, printDate);
    doc.text(15, 10, 'Reservierungen für den ' + moment(this.currentDate).format('DD.MM.YYYY'));

    doc.autoTable({
      bodyStyles: {
        valign: 'top'
      },
      styles: {
        overflow: 'linebreak'
      },
      headStyles: { fillColor: [0, 0, 0] },
      body: parsedReservations,
      columns: [
        { header: 'Uhrzeit', dataKey: 'reservedFor' },
        { header: 'Name', dataKey: 'guestName' },
        { header: 'Gäste', dataKey: 'peopleCount' },
        { header: 'Tisch', dataKey: 'tablesString' },
        { header: 'Tags', dataKey: 'tagsString' }
        // { header: 'Status', dataKey: 'status' }
      ]
    });

    // Save the PDF
    doc.save(`Reservierungen_${moment(this.currentDate).format('YYYY-MM-DD')}.pdf`);
  }

  sortTablesComparator(propA: any, propB: any, rowA: any, rowB: any) {
    // do something with rowA and rowB objects.
    if (rowA.tables[0] && rowB.tables[0]) {
      if (rowA.tables[0].name < rowB.tables[0].name) {
        return -1;
      }
      if (rowA.tables[0].name > rowB.tables[0].name) {
        return 1;
      }
    }
    return 0;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.snackBar.open('Bearbeitungsmodus beendet.', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
      if (this.scheduler) {
        this.scheduler.instance.repaint();
      }
    } else {
      this.snackBar.open('Bearbeitungsmodus gestartet. Sie können nun Reservierungen verschieben.', '', {
        duration: 2000,
        panelClass: ['snackbar-success']
      });
    }
  }

  onRowActivate(event: any) {
    if (event.type === 'click' && event.column.prop !== 'status') {
      this.editReservation(event.row);
    }
  }

  openAllPending() {
    if (!this.isArchive) {
      let appendContainer = 'body';
      if (this.isFullscreen) {
        appendContainer = '.fullscreen-element';
      }

      const modalRef = this.modalService.open(PendingReservationsComponent, {
        size: 'lg',
        container: appendContainer
      });
    }
  }

  fsChanged(event: any) {
    this.reservationService.fsSubject.next(event || 14);
  }

  getResTimeFilter() {
    this.reservationService
      .getResTimeFilter()
      .takeUntil(this.ngUnsubscribe)
      .subscribe((resTimeFilter: any) => {
        if (resTimeFilter && resTimeFilter.length > 0) {
          this.timeFilter = resTimeFilter;
        }
      });
  }

  checkSelectedFilter(name: any) {
    if (this.selectedTimeFilter.includes(name)) {
      return true;
    }
    return false;
  }

  filterReservationWithTime(name: any = '', repaint: boolean = false) {
    let timeFilterValue: any = {};
    if (name) {
      if (this.selectedTimeFilter.includes(name)) {
        const indexToDelete = this.selectedTimeFilter.indexOf(name);

        if (indexToDelete !== -1) {
          this.selectedTimeFilter.splice(indexToDelete, 1);
        }
      } else {
        this.selectedTimeFilter = [name];
      }
    }
    // let temp = this.tempRes && this.tempRes.length > 0 ? this.tempRes : this.temp;
    let temp = this.tempRes;
    this.reservations = temp;
    // let data: any = [];
    let data2: any = [];
    if (this.bookSettings) {
      this.bookSettings.startTime = this.bookSettings.startTimeOriginal;
      this.bookSettings.endTime = this.bookSettings.endTimeOriginal;
    }
    if (this.selectedTimeFilter && this.selectedTimeFilter.length > 0) {
      for (let item of this.selectedTimeFilter) {
        let filterValue = this.timeFilter.find((el: any) => {
          return item == el.name;
        });
        if (filterValue) {
          timeFilterValue.from = filterValue.from;
          timeFilterValue.to = filterValue.to;
          if (timeFilterValue.from && timeFilterValue.to) {
            if (this.bookSettings && Object.keys(this.bookSettings).length > 0) {
              this.bookSettings.startTime = parseInt(timeFilterValue.from.split(':')[0], 10);
              this.bookSettings.endTime =
                parseInt(timeFilterValue.to.split(':')[0], 10) < this.bookSettings.startTime
                  ? 24
                  : parseInt(timeFilterValue.to.split(':')[0], 10);
            }

            // if (this.resetTimeFilter) {
            //   timeFilterValue.from = '';
            //   timeFilterValue.to = '';
            // }
            // this.resetTimeFilter = false;
            const startDate = new Date(this.currentDate);
            const endDate = new Date(this.currentDate);
            if (timeFilterValue.from && timeFilterValue.from.length > 0) {
              const [hours, minutes] = timeFilterValue.from.split(':');
              startDate.setHours(parseInt(hours, 10)); // base 10
              startDate.setMinutes(parseInt(minutes, 10));
              startDate.setSeconds(0);
              startDate.setMilliseconds(0);
            } else {
              startDate.setHours(0, 0, 0, 0);
            }
            if (timeFilterValue.to && timeFilterValue.to.length > 0) {
              const [hours, minutes] = timeFilterValue.to.split(':');
              endDate.setHours(parseInt(hours, 10)); // base 10
              endDate.setMinutes(parseInt(minutes, 10));
              endDate.setSeconds(0);
              endDate.setMilliseconds(0);
            } else {
              endDate.setHours(0, 0, 0, 0);
            }
            temp.map((item: any) => {
              const reservedDate = new Date(item.reservedFor); // Parse reservedFor date
              reservedDate.setMilliseconds(0);

              // Debugging to ensure comparison values
              // console.log('Time Filter:', timeFilterValue);
              // console.log('Reserved For:', reservedDate, 'Start Date:', startDate, 'End Date:', endDate);

              // if (timeFilterValue.to >= timeFilterValue.from) {
              //   if (reservedDate >= startDate && reservedDate <= endDate) {
              //     console.log('Matched:', item.reservedFor);
              //     data.push(item);
              //   }
              // } else {
              //   if (reservedDate >= startDate && reservedDate <= addDays(endDate, 1)) {
              //     console.log('Matched (Cross Midnight):', item.reservedFor);
              //     data.push(item);
              //   }
              // }

              const reservedDate2 = new Date(item.reservedFor).getTime(); // Get timestamp for comparison
              const startTime = startDate.getTime();
              const endTime = endDate.getTime();
              // console.log(
              //   'Reserved For (Timestamp):',
              //   reservedDate2,
              //   'Start (Timestamp):',
              //   startTime,
              //   'End (Timestamp):',
              //   endTime
              // );

              if (timeFilterValue.to >= timeFilterValue.from) {
                // Simple range comparison using getTime()
                if (reservedDate2 >= startTime && reservedDate2 <= endTime) {
                  // console.log('Matched2:', item.reservedFor);
                  data2.push(item);
                }
              } else {
                // Handle cases where 'to' is earlier than 'from' (crossing midnight)
                if (reservedDate2 >= startTime || reservedDate2 <= addDays(endDate, 1).getTime()) {
                  // console.log('Matched (Cross Midnight)2:', item.reservedFor);
                  data2.push(item);
                }
              }
            });
          }
        }
      }
      // console.log('final1', data);
      // console.log('final2', data2);
      this.reservations = data2;
    }
    this.reservations = this.setStatusInfo(this.view, this.reservations);
    if (this.view === 'timeline' && repaint) {
      setTimeout(() => {
        this.scheduler.instance.repaint();
      }, 100);
    }
  }

  viewExtraFilters() {
    this.showExtraFilters = !this.showExtraFilters;
  }

  setStatusInfo(view: any, reservations: any) {
    reservations = reservations.map((item: any) => {
      let statusInfo: any;
      if (Constants.fixedStatusMap[item.status]) {
        if (view == 'timeline') {
          statusInfo = [];
        } else {
          statusInfo = Constants.fixedStatusMap[item.status];
        }
      } else {
        let customStatus = this.allStatus.find((status: any) => status.value === item.status);
        if (customStatus) {
          statusInfo = customStatus;
        } else {
          statusInfo = { ...Constants.defaultStatus, label: item.status, value: item.status };
        }
      }
      return { ...item, statusInfo };
    });
    return reservations;
  }
}

@Component({
  selector: 'app-add-bottom-sheet',
  template: `
    <mat-nav-list>
      <a href="#" mat-list-item (click)="$event.preventDefault(); addReservation()">
        <span mat-line>Neue Reservierung</span>
        <span mat-line>Erstellen Sie eine Reservierung <b>mit</b> Gastdaten</span>
      </a>
      <a href="#" mat-list-item (click)="$event.preventDefault(); addWalkin()">
        <span mat-line>Neuen Walk-In</span>
        <span mat-line>Erstellen Sie eine Reservierung <b>ohne</b> Gastdaten</span>
      </a>
    </mat-nav-list>
  `
})
export class AddBottomSheetComponent {
  constructor(private bottomSheetRef: MatBottomSheetRef<AddBottomSheetComponent>) {}

  addReservation() {
    this.bottomSheetRef.dismiss('reservation');
  }
  addWalkin() {
    this.bottomSheetRef.dismiss('walkin');
  }
}
